import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AppRoute = ({ component: Component, isAuthProtected, isAuthorized, ...rest }) => {
  const profile = useSelector(state => state.customer.profile);

  if (isAuthorized) {
    return (<Route
      {...rest}
      render={(props) => (profile.email && profile.verified) ? (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />) :
        <Component {...props} />
      }
    />);
  };

  return (
    <Route
      {...rest}
      render={(props) => (isAuthProtected && profile.email) ?
        <Component {...props} /> :
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    />
  );
};

export default AppRoute;
