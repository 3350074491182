export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';

export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';

export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_FEATURED_PRODUCTS_SUCCESS = 'GET_FEATURED_PRODUCTS_SUCCESS';
export const  GET_FEATURED_PRODUCTS_FAILURE = 'GET_FEATURED_PRODUCTS_FAILURE';

export const GET_LATEST_PRODUCTS_SUCCESS = 'GET_LATEST_PRODUCTS_SUCCESS';
export const GET_LATEST_PRODUCTS_FAILURE = 'GET_LATEST_PRODUCTS_FAILURE';

export const GET_OFFERS_PRODUCTS_SUCCESS = 'GET_OFFERS_PRODUCTS_SUCCESS';
export const GET_OFFERS_PRODUCTS_FAILURE = 'GET_OFFERS_PRODUCTS_FAILURE';

export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_FAILURE = 'GET_SINGLE_PRODUCT_FAILURE';

export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';

export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';

export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
export const GET_CONTENTS_FAILURE = 'GET_CONTENTS_FAILURE';

export const GET_CLIENT_TESTIMONIALS_SUCCESS = 'GET_CLIENT_TESTIMONIALS_SUCCESS';
export const GET_CLIENT_TESTIMONIALS_FAILURE = 'GET_CLIENT_TESTIMONIALS_FAILURE';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const CHECKOUT_ORDER_SUCCESS = 'CHECKOUT_ORDER_SUCCESS';
export const CHECKOUT_ORDER_FAILURE = 'CHECKOUT_ORDER_FAILURE';

export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const GET_ALL_ORDER_SUCCESS = 'GET_ALL_ORDER_SUCCESS';
export const GET_ALL_ORDER_FAILURE = 'GET_ALL_ORDER_FAILURE';

export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILURE = 'SUBMIT_REVIEW_FAILURE';

export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';

export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS';
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

export const SET_META_DATA = 'SET_META_DATA';
export const SET_BREADCRUMB_DATA = 'SET_BREADCRUMB_DATA';


export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const LOAD_CART_DATA = 'LOAD_CART_DATA';

