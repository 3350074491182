import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../../actions/customer";
import { showSuccessSnackbar } from "../../../actions/snackbar";
import { useDispatch } from "react-redux";

const AccountButton = () => {
  const [toggle, setToggel] = useState(false);
  const showToggel = () => setToggel(!toggle);
  const userState = localStorage.getItem("userInfo");
  const history = useHistory();
  const dispatch = useDispatch();

  const ref = useRef();

  const onLogout = useCallback(() => {
    dispatch(logout());
    dispatch(showSuccessSnackbar("You are logged out!"));
  }, [dispatch]);

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setToggel(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const Dropdown = () => {
    return (
      <div
        className={
          toggle ? "custom-popover custom-popover-active" : "custom-popover"
        }
      >
        {userState ? (
          <div
            onClick={() => {
              history.push("/profile");
              showToggel();
            }}
            className='popover-item'
          >
            My Profile
          </div>
        ) : null}
        {userState ? (
          <div
            onClick={() => {
              history.push("/orders-history");
              showToggel();
            }}
            className='popover-item'
          >
            My Orders
          </div>
        ) : null}
        {userState ? (
          <div
            onClick={() => {
              history.push("/change-password");
              showToggel();
            }}
            className='popover-item'
          >
            Change Password
          </div>
        ) : null}
        {userState ? (
          <div
            onClick={() => {
              onLogout();
              showToggel();
            }}
            className='popover-item'
          >
            Logout
          </div>
        ) : (
          <div
            onClick={() => history.push("/login")}
            className='popover-item text-center'
          >
            Sign up/login
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='btn_login' ref={ref}>
      {localStorage.getItem("userInfo") ? (
        <div style={{ position: "relative" }}>
          <Button onClick={showToggel} className='login' variant='outlined'>
            My Account
            <i
              className='fa fa-angle-down'
              aria-hidden='true'
              style={{ fontSize: 20, marginLeft: 8 }}
            />
          </Button>
          <Dropdown />
        </div>
      ) : (
        <Link to={"/login"}>
          <Button className='login' variant='outlined'>
            Login/Register
          </Button>
        </Link>
      )}
    </div>
  );
};

export default AccountButton;
