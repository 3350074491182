import {
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { profile: { verified: false, cart: [] }, customer: [], errors: null };

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state, profile: { ...state.profile, ...action.payload.data }, errors: null
      }
    case SIGNUP_FAILURE:
      return {
      ...state, errors: action.payload.response.data?.[0]?.message
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('authToken', action.payload.token);
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      localStorage.setItem('doorHandleUserId', action.payload.customer.id);
      return { ...state, profile: { ...action.payload.customer, verified: true }, customer: action.payload.customer, errors: null };
    case LOGIN_FAILURE:
      return {
        ...state, errors: action.payload.error
      }
    case LOGOUT_SUCCESS:
      localStorage.removeItem('authToken');
      localStorage.removeItem('doorHandleUserId');
      localStorage.removeItem('userInfo');
      return {
        ...state, profile: { verified: false }
      }
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state, customer: { ...action.payload.data, cart: JSON.parse(action.payload.data.cart_data), verified: true }
      }
    case GET_CUSTOMER_FAILURE:
      localStorage.removeItem('lcCartProducts');
      return {
        ...state, customer: { ...action.payload.data, cart: [], verified: false }
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state, customer: { ...state.customer, ...action.payload.data}
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state, profile: { ...state.profile, ...action.payload.data }
      }
    default:
      return state;
  }
}
