import { combineReducers } from 'redux';
import customer from './customer';
import snackbar from './snackbar';
import menus from './menus';
import contents from './contents';
import settings from './settings';
import banners from './banners';
import products from './products';
import categories from './categories';
import cart from './cart';
import orders from './orders';
import testimonials from "./testimonials";
import assets from "./assets";


const appReducers = combineReducers({
    customer,
    snackbar,
    menus,
    contents,
    settings,
    banners,
    products,
    categories,
    cart,
    orders,
    testimonials,
    assets
  });
  
  export default appReducers;