import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_FEATURED_PRODUCTS_SUCCESS,
  GET_FEATURED_PRODUCTS_FAILURE,
  GET_LATEST_PRODUCTS_SUCCESS,
  GET_LATEST_PRODUCTS_FAILURE,
  GET_OFFERS_PRODUCTS_SUCCESS,
  GET_OFFERS_PRODUCTS_FAILURE,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  data: [], // this is used to store the list of the products
  product: {}, // this is used to store the single product
  featuredProducts: [],
  BestOffers: [],
  latestProducts: [],
  error: '',
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state, error: action.payload.error
      }
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, product: action.payload.data };
    case GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state, error: action.payload.error
      }
      case GET_LATEST_PRODUCTS_SUCCESS:
        return {
          ...state, latestProducts: action.payload
        }
      case GET_LATEST_PRODUCTS_FAILURE:
      return {
        ...state, latestProducts:[]
      }
      case GET_FEATURED_PRODUCTS_SUCCESS:
        return {
          ...state, featuredProducts: action.payload
        }
      case GET_FEATURED_PRODUCTS_FAILURE:
      return {
        ...state, featuredProducts: []
      }
      case GET_OFFERS_PRODUCTS_SUCCESS:
        return {
          ...state, BestOffers: action.payload
        }
      case GET_OFFERS_PRODUCTS_FAILURE:
      return {
        ...state, BestOffers: []
      }
    default:
      return state;
  }
}
