import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
// import { fetchFooterData } from '../../../Services';
import { getMenus } from "../../../actions/menus";
import { useDispatch, useSelector } from "react-redux";
import { getContents } from '../../../actions/contents';
import { dataUrl, getContentByName, getSettingByKey } from '../../../helpers/common';
import { getSettings } from "../../../actions/settings";
import { getBanners } from "../../../actions/banners";
import striptags from 'striptags';
import { loadCartData } from '../../../actions/cart';
import { getAssets } from '../../../actions/assets';

export default function Footer() {
    
    const dispatch = useDispatch();
    const menus = useSelector((state) => state.menus.menus);
    const contents = useSelector((state) => state.contents.data);
    const settings = useSelector((state) => state.settings.data);
    const assets = useSelector(state => state.assets.data);
    const footerLogo = assets?.find(asset => asset.name === 'website_footer_logo');

    let useful_links = menus.filter(menu => menu.category === 'Useful Link');

    let footer_products = menus.filter(menu => menu.category === 'Products');

    const footer_about_us_text = getContentByName(contents, 'footer_about_us_text', {content: ""});

    const footer_description = getContentByName(contents, 'footer_description', {content: ""});

    const company_contact_number = getSettingByKey(settings, "company_contact_number");

    const company_email_address = getSettingByKey(settings, "company_email_address");

    const socialLinkFacebook = getSettingByKey(settings, "social_links_facebook");

    const socialLinkDribble = getSettingByKey(settings, "social_links_dribble");

    const socialLinkPinterest = getSettingByKey(settings, "social_links_pinterest");

    const socialLinkTwitter = getSettingByKey(settings, "social_links_twitter");

    const company_address = getSettingByKey(settings, "company_address", {});

    const footer_copyright_message = getSettingByKey(settings, "footer_copyright_message");

    useEffect(() => {
        dispatch(getMenus());
        dispatch(getContents());
        dispatch(getSettings());
        dispatch(getAssets());
        dispatch(getBanners("Homepage Banners"));
        dispatch(getBanners("Homepage Small Banners"));
        dispatch(loadCartData());
    }, [dispatch]);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    return (
        <div className="footer">
                <div className="container">
                    <div className="col-md-3 footer-grid">
                        <h3>About Us</h3>
                        <p>{striptags(footer_about_us_text?.content)}</p>
                    </div>
                    <div className="col-md-3 footer-grid ">
                        <h3>Products</h3>
                        <ul>
                            {footer_products.map(product => (
                                <li key={product.id} ><Link to={product.url}>{product.name}</Link></li>
                            ))}
                            {/* <li><Link to="/contact">Contact</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-md-3 footer-grid ">
                        <h3>Useful Links</h3>
                        <ul>
                            {useful_links.map(useful_link => (
                                <li key={useful_link.id}><Link to={useful_link.url}><span>{useful_link.name}</span></Link></li>
                            ))}
                                
                        </ul>
                    </div>
                    <div className="col-md-3 footer-grid">
                        <h3>My Account</h3>
                        <ul>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/signup">Register</Link></li>
                        </ul>
                    </div>
                    <div className="clearfix" />
                    <div className="footer-bottom">
                        <Link to="/"><img src={`${dataUrl}/assets/${footerLogo?.file_name}`} alt="logo" className='img-responsive' style={{margin: '0 auto'}} /></Link>

                        <p className="fo-para">{striptags(footer_description.content)}</p>
                        <ul className="social-fo">
                            {socialLinkFacebook ? <li onClick={() => openInNewTab(socialLinkFacebook.value)} ><Link to='.'  className=" face"><i className="fa fa-facebook" aria-hidden="true" /></Link></li> : null}
                            {socialLinkTwitter ?  <li onClick={() => openInNewTab(socialLinkTwitter.value)} ><Link to='.' className=" twi"><i className="fa fa-twitter" aria-hidden="true" /></Link></li> : null }
                            {socialLinkPinterest ?  <li onClick={() => openInNewTab(socialLinkPinterest.value)}><Link to='.'  className=" pin"><i className="fa fa-pinterest-p" aria-hidden="true" /></Link></li> : null}
                            {socialLinkDribble ? <li onClick={() => openInNewTab(socialLinkDribble.value)}><Link to='.' className=" dri"><i className="fa fa-dribbble" aria-hidden="true" /></Link></li> : null}
                        </ul>
                        <div className=" address">
                            <div className="col-md-4 fo-grid1">
                                <p><i className="fa fa-home" aria-hidden="true" />{company_address?.value}</p>
                            </div>
                            <div className="col-md-4 fo-grid1">
                                <p><i className="fa fa-phone" aria-hidden="true" />{company_contact_number?.value}</p>
                            </div>
                            <div className="col-md-4 fo-grid1">
                                <p><Link to={`mailto:${company_email_address?.value}`}><i className="fa fa-envelope-o" aria-hidden="true" />{company_email_address?.value}</Link></p>
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                    <div className="copy-right">
                        <p>{footer_copyright_message?.value}</p>
                    </div>
                </div>
            </div>
    )
}
