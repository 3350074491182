import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  faSearch,
  faUserAlt,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dataUrl } from "../../../helpers/common";
import { useSelector } from "react-redux";

export default function Mobileheader(props) {
  const history = useHistory();
  const assets = useSelector((state) => state.assets.data);
  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");


  return (
    <div className="mobile_header">
      <div className="logo-sec">
        <div className="logo">
          <Link to="/"><img src={`${dataUrl}/assets/${websiteLogo?.file_name}`} alt="logo" /></Link>
        </div>
        <div className="header-icons">
          <div className="user-icon">
            <FontAwesomeIcon
              onClick={props.showToggel}
              className="cursor-pointer"
              color="#fff"
              icon={faUserAlt}
            />
            {props.popover}
          </div>

          <div className="cart-icon" onClick={() => history.push("/cart")}>
            <span className="item_count">{props.cartData?.length}</span>
            <FontAwesomeIcon
              className="cursor-pointer"
              color="#fff"
              icon={faShoppingCart}
            />
          </div>
          <div
            className="search-icon"
            onClick={() => history.push("/search-page")}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              color="#fff"
              icon={faSearch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
