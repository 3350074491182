import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { showErrorSnackbar } from "../../../actions/snackbar";

const HeaderSearch = () => {
  let { keyword } = useParams();
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (!searchKeyword) {
        dispatch(showErrorSnackbar("Please enter any keyword", "warning"));
        return false;
      } else {
        history.push("/search/" + searchKeyword);
      }
    },
    [searchKeyword, dispatch, history]
  );

  return (
    <div className='search-form'>
      <form onSubmit={handleSearch}>
        <input
          type='text'
          name='search'
          placeholder='Search for Products...'
          defaultValue={searchKeyword}
          onKeyUp={(e) => setSearchKeyword(e.target.value)}
        />
        <button className='btn search__btn'>
          <i className='fa fa-search' aria-hidden='true'></i>
        </button>
      </form>
    </div>
  );
};

export default HeaderSearch;
