import { createAction } from 'redux-actions';
import axios from "axios";
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SUBMIT_REVIEW_SUCCESS,
  SUBMIT_REVIEW_FAILURE,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_FEATURED_PRODUCTS_SUCCESS,
  GET_FEATURED_PRODUCTS_FAILURE,
  GET_LATEST_PRODUCTS_FAILURE,
  GET_LATEST_PRODUCTS_SUCCESS,
  GET_OFFERS_PRODUCTS_SUCCESS,
  GET_OFFERS_PRODUCTS_FAILURE
} from '../constants/actionTypes';
import { getAllReviews, getItemList, getProduct, postReview } from '../api/items'
import { showSuccessSnackbar } from './snackbar';
import { dataUrl } from '../helpers/common';
import { server_url } from './constants';

const getProductsSuccess = createAction(GET_PRODUCTS_SUCCESS);
const getProductsFailure = createAction(GET_PRODUCTS_FAILURE);

export function getProducts(queryParamsObj, perPage, page) {
  return dispatch => getItemList('products', queryParamsObj, perPage, page)
    .then((json) => dispatch(getProductsSuccess({
      ...json,
      productLabels: queryParamsObj.product_labels,
    })))
    .catch(error => dispatch(getProductsFailure(error)))
}

const getSingleProductSuccess = createAction(GET_SINGLE_PRODUCT_SUCCESS);
const getSingleProductFailure = createAction(GET_SINGLE_PRODUCT_FAILURE);

export function getSingleProduct(productId) {
  return dispatch => getProduct(productId)
    .then((json) => dispatch(getSingleProductSuccess({
      ...json,
    })))
    .catch(error => dispatch(getSingleProductFailure(error)))
}

export const getSinglProduct = async (id) => {
  return axios.get(
    `${dataUrl}/products/` + id
  );
};

const submitReviewSuccess = createAction(SUBMIT_REVIEW_SUCCESS);
const submitReviewFailure = createAction(SUBMIT_REVIEW_FAILURE);

export function submitNewReview(params, customer) {
  console.log('customer', customer);
  return dispatch => postReview(params)
    .then(data => {
      dispatch(showSuccessSnackbar('Your review has been submitted'));
      dispatch(submitReviewSuccess({ ...params, customer }));
      return true;
    })
    .catch(error => {
      dispatch(submitReviewFailure(error));
      return false;
    });
}

const getReviewsSuccess = createAction(GET_REVIEWS_SUCCESS);
const getReviewsFailure = createAction(GET_REVIEWS_FAILURE);

export function getReviews(productId) {
  return dispatch => getAllReviews(productId)
    .then(data => dispatch(getReviewsSuccess(data)))
    .catch(error => dispatch(getReviewsFailure(error)));
}

export const fetchFeaturedProducts = async (dispatch) => {
  try {
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[product_labels]=Featured Products`
    );
    dispatch({ type: GET_FEATURED_PRODUCTS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_FEATURED_PRODUCTS_FAILURE });
  }
};

export const fetchProductsByConditions = async (conditions) => {
  return axios.get(
    `${server_url}/products?&per-page=200&page=1&` + conditions
  );
};

export const fetchOffersProducts = async (dispatch) => {
  try {
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[product_labels]=Best Offers`
    );
    dispatch({ type: GET_OFFERS_PRODUCTS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_OFFERS_PRODUCTS_FAILURE });
  }
};

export const fetchLatestProducts = async (dispatch) => {
  try {
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[product_labels]=Latest Products`
    );
    dispatch({ type: GET_LATEST_PRODUCTS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_LATEST_PRODUCTS_FAILURE });
  }
};