import ajax from './ajax';

export function signUpRequest(params) {
  return ajax('/customers', { method: 'POST', data: params });
}

export function verifyOtp(params) {
  return ajax('/customers/verify_mobile_otp', { method: 'POST', data: params });
}

// export function login(params) {
//   return ajax('/customers/login', { method: 'POST', data: params });
// }

export function login(params) {
  return ajax('/customers/login_using_email', { method: 'POST', data: params });
}

export function updateProfile(params) {
  return ajax(`/customers/update_customer`, { method: 'POST', data: params });
}

export function getCustomerDetails(userId) {
  return ajax(`/customers/${userId}`);
}

// export function forgetEmailPassword(params) {
//   return ajax('/customers/forgot_password', { method: 'POST', data: params });
// }

export function forgetEmailPassword(params) {
  return ajax('/customers/forgot_email_password', { method: 'POST', data: params });
}

// export function forgetEmailPasswordOtpCheck(params) {
//   return ajax('/customers/verify_forgot_password_otp', { method: 'POST', data: params });
// }

export function forgetEmailPasswordOtpCheck(email, email_verification_otp) {
  return ajax('/customers/verify_email_forgot_password_otp', { method: 'POST', data: {email, email_verification_otp} });
}

export function changePassword(params) {
  return ajax('/customers/change_password', { method: 'POST', data: params });
}
