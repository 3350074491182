/* eslint-disable linebreak-style */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCustomerDetails, logout } from "./actions/customer";
import { showErrorSnackbar } from "./actions/snackbar";
import Layout from "./components/Layout";
import "./index.css"


const PATHS = [
];

function App() {
  const dispatch = useDispatch();
  const customerId = localStorage.getItem("doorHandleUserId");

  useEffect(() => {
    PATHS.forEach((path) => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;

      document.body.appendChild(script);
    });
  }, []);

  // if (localStorage.getItem("userInfo")) {
  //   store.dispatch({ type: LOGIN_SUCCESS, payload: JSON.parse(localStorage.getItem("userInfo")) });
  // }

  useEffect(() => {

    if (customerId) {
      dispatch(getCustomerDetails(customerId)).then((res) => {
        if (res.message.includes('401') || res.message.includes('403')) {
          dispatch(logout());
          dispatch(showErrorSnackbar('Session expired. Please login again.'));
        }
      });
    }
  }, [customerId, dispatch]);

  return <Layout />;
}

export default App;
