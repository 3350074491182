import axios from "axios";
import { createAction } from 'redux-actions';
import * as Auth from '../api/customer';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS
} from '../constants/actionTypes';
import { apiUrl } from "../helpers/common";
import { showErrorSnackbar, showSuccessSnackbar } from './snackbar';

const signUpSuccess = createAction(SIGNUP_SUCCESS)
const signUpFailure = createAction(SIGNUP_FAILURE);

export function signUp(params) {
  return dispatch => Auth.signUpRequest(params)
    .then((json) => {
      dispatch(signUpSuccess(json));
      dispatch(showSuccessSnackbar('An OTP has been sent to your email!'))
      return true;
    })
    .catch(error => {
      dispatch(showErrorSnackbar(error))
      dispatch(signUpFailure(error));
      return false;
    });
}

const verifyOtpSuccess = createAction(VERIFY_OTP_SUCCESS);
const verifyOtpFailure = createAction(VERIFY_OTP_FAILURE);

export function verifyOtp(params) {
  return dispatch => Auth.verifyOtp(params)
    .then(json => {
      dispatch(showSuccessSnackbar('OTP verified successfully'));
      dispatch(verifyOtpSuccess(json));
      return true;
    })
    .catch(error => {
      dispatch(showErrorSnackbar('OTP verification failed ! Please try again !'));
      dispatch(verifyOtpFailure(error));
      return false;
    })
}

const loginSuccess = createAction(LOGIN_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);

export function login(params) {
  return dispatch => Auth.login(params)
    .then(json => {
      dispatch(loginSuccess(json.data));
      dispatch(showSuccessSnackbar('Logged in successfully'))
      return true;
    })
    .catch(error => {
      dispatch(loginFailure({ error: 'Invalid email or password' }));
      return false;
    });
}

const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS);
const updateProfileFailure = createAction(UPDATE_PROFILE_FAILURE);

export function updateProfile(params) {
  return dispatch => Auth.updateProfile(params)
    .then(json => {
      dispatch(updateProfileSuccess(json));
      return json;
    })
    .catch(error => {
      dispatch(updateProfileFailure(error));
      return error;
    })
}

const getProfileSucess = createAction(GET_CUSTOMER_SUCCESS);
const getProfileFailure = createAction(GET_CUSTOMER_FAILURE);

export function getCustomerDetails(userId) {
  return dispatch => Auth.getCustomerDetails(userId)
    .then((json) => {
      dispatch(getProfileSucess(json));
      return json;
    })
    .catch(error => {
      getProfileFailure(error);
      return error;
    })
}

const logoutSuccess = createAction(LOGOUT_SUCCESS);

export function logout() {
  return dispatch => dispatch(logoutSuccess());
}

const forgetPasswordSuccess = createAction(FORGET_PASSWORD_SUCCESS);
const forgetPasswordFailure = createAction(FORGET_PASSWORD_FAILURE);

export function forgetEmailPassword(params) {
  return dispatch => Auth.forgetEmailPassword(params)
    .then((json) => {
      dispatch(forgetPasswordSuccess(json));
      dispatch(showSuccessSnackbar(`An OTP sent to your email ${params.email.email}`));
      return true;
    }).catch(error => {
      dispatch(showErrorSnackbar('The email address may be wrong!'))
      dispatch(forgetPasswordFailure(error));
      return false;
    });
}

// login with password
export const loginByPassword = (formData) => {
  return axios.post(
    `${apiUrl}/customers/login_using_email`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

// signUp using email
export const signupUser = (formData) => {
  return axios.post(
    `${apiUrl}/customers`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};


export const verifyEmail = (email, email_verification_otp) => {
  return axios.post(
    `${apiUrl}/customers/verify_email_otp`,
    { email, email_verification_otp },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const resendEmailOtp = (email) => {
  return axios.post(
    `${apiUrl}/customers/resend_email_otp`,
    { email },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const fetchUserDetails = (token) => {
  return axios.get(`${apiUrl}/customers/details`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getErrorMsg = (err) => {
  let msg = "Something went wrong! Please try again later!";
  if (err.response && err.response.data && err.response.data[0] && err.response.data[0].message) {
      msg = err.response.data[0].message;
  } else if (err.response && err.response.data && err.response.data.message) {
      msg = err.response.data.message;
  }
  return msg;
}