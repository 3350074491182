import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/customer";
import { getCategory } from "../../../actions/categories";
import {
  fetchFeaturedProducts,
  fetchLatestProducts,
  fetchOffersProducts,
} from "../../../actions/products";
import { showSuccessSnackbar } from "../../../actions/snackbar";
import Sidebar from "../../sidebar";
import MobileHeader from "./mobile-header";
import { dataUrl, getSettingByKey } from "../../../helpers/common";
import HeaderSearch from "./HeaderSearch";
import AccountButton from "./AccountButton";

export default function Header() {
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const menus = useSelector((state) => state.menus.menus);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [toggle, setToggel] = useState(false);
  const showToggel = () => setToggel(!toggle);
  const userState = localStorage.getItem("userInfo");
  const assets = useSelector((state) => state.assets.data);
  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");

  const settings = useSelector((state) => state.settings.data);
  const companyContactNo = getSettingByKey(
    settings,
    "company_contact_number"
  )?.value;
  const headerInfoText = getSettingByKey(settings, "header_info_text")?.value;

  useEffect(() => {
    // const handler = () => {
    //   setToggel(false);
    // };
    // document.addEventListener("mousedown", handler);
    // return () => {
    //   document.removeEventListener("mousedown", handler);
    // };
  });

  const [navClass, setnavClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  });

  function scrollNavigation() {
    var scrollup = window.scrollY;
    if (scrollup > 0) {
      setnavClass("sticky");
    } else {
      setnavClass("");
    }
  }

  const cartData = useSelector((state) => state.cart.data);
  let top_menus = menus.filter((menu) => menu?.category === "Top Menu");

  const getMainMenus = (menus) => {
    return menus?.filter((menu) => menu?.parent_id === 0)?.slice(0, 6);
  };

  const getChildMenus = (menus, parentMenu) => {
    return menus?.filter((menu) => menu?.parent_id === parentMenu?.id);
  };

  const hasChildMenus = (menu) => {
    return getChildMenus(top_menus, menu)?.length > 0;
  };

  const onLogout = useCallback(() => {
    dispatch(logout());
    dispatch(showSuccessSnackbar("You are logged out!"));
  }, [dispatch]);

  const loadFeaturedProducts = useCallback(() => {
    fetchFeaturedProducts(dispatch);
  }, [dispatch]);

  const loadLatestProducts = useCallback(() => {
    fetchLatestProducts(dispatch);
  }, [dispatch]);

  const loadOffersProducts = useCallback(() => {
    fetchOffersProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    loadFeaturedProducts();
    loadLatestProducts();
    loadOffersProducts();
  }, [loadFeaturedProducts, loadLatestProducts, loadOffersProducts]);

  const popover = (
    <div
      className={
        toggle ? "custom-popover custom-popover-active" : "custom-popover"
      }
    >
      {userState ? (
        <div
          onClick={() => {
            history.push("/profile");
            showToggel();
          }}
          className='popover-item'
        >
          My Profile
        </div>
      ) : null}
      {userState ? (
        <div
          onClick={() => {
            history.push("/orders-history");
            showToggel();
          }}
          className='popover-item'
        >
          My Orders
        </div>
      ) : null}
      {userState ? (
        <div
          onClick={() => {
            history.push("/change-password");
            showToggel();
          }}
          className='popover-item'
        >
          Change Password
        </div>
      ) : null}
      {userState ? (
        <div
          onClick={() => {
            onLogout();
            showToggel();
          }}
          className='popover-item'
        >
          Logout
        </div>
      ) : (
        <div
          onClick={() => history.push("/login")}
          className='popover-item text-center'
        >
          Sign up/login
        </div>
      )}
    </div>
  );

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  return (
    <div>
      <div className='header_info top'>
        <p>
          <strong>
            <i className='fa fa-truck' aria-hidden='true' /> {headerInfoText}
          </strong>
        </p>

        <a href={`tel:${companyContactNo}`}>
          <i className='fa fa-phone' aria-hidden='true' /> {companyContactNo}
        </a>
      </div>
      <div className={`my-header navClass ${navClass}`}>
        <div className='header_info'>
          <div className='logo'>
            <Link to='/'>
              <img
                src={`${dataUrl}/assets/${websiteLogo?.file_name}`}
                alt='logo'
              />
            </Link>
          </div>
          <HeaderSearch />
          <div className='navbar_btn'>
            <AccountButton />

            <div className='cart'>
              <Link to='/cart'>
                <Button className='cart_item' variant='outlined'>
                  <span className='item_count'>{cartData?.length}</span>
                  <i
                    className='fa fa-shopping-cart my-cart-icon'
                    aria-hidden='true'
                  />
                  Cart
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <MobileHeader
          cartData={cartData}
          showToggel={showToggel}
          popover={popover}
        />

        <div className='header_info_2'>
          <div className='nav-top'>
            <nav className='navbar navbar-default'>
              <button
                type='button'
                className='navbar-toggle collapsed navbar-toggle1'
                data-toggle='collapse'
                data-target='#bs-megadropdown-tabs'
                onClick={showSidebar}
              >
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar' />
                <span className='icon-bar' />
                <span className='icon-bar' />
              </button>
              <div className='collapse navbar-collapse'>
                <ul className='nav navbar-nav'>
                  {getMainMenus(top_menus).map((menu) => (
                    <li key={menu?.id} className='main-menu-item'>
                      <Link
                        to={menu?.url}
                        className={`hyper ${
                          pathname === menu?.url ? "active" : ""
                        }`}
                      >
                        <span>
                          {menu?.name}{" "}
                          {hasChildMenus(menu) && (
                            <i
                              className='fa fa-angle-down'
                              aria-hidden='true'
                              style={{ fontSize: 16, marginLeft: 3 }}
                            />
                          )}
                        </span>
                      </Link>

                      {hasChildMenus(menu) && (
                        <ul className='dropdown-box'>
                          {getChildMenus(top_menus, menu)?.map((item) => (
                            <li key={item?.id}>
                              <Link
                                to={item?.url}
                                className={`child-menu-item ${
                                  pathname === item?.url ? "active" : ""
                                }`}
                              >
                                <span>{item?.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              
              <Sidebar
                setSidebar={setSidebar}
                sidebar={sidebar}
                showSidebar={showSidebar}
                top_menus={top_menus}
                getMainMenus={getMainMenus}
                getChildMenus={getChildMenus}
                hasChildMenus={hasChildMenus}

              />
            </nav>
            <div className='clearfix' />
          </div>
          {/* end nav bar  */}
        </div>
      </div>
    </div>
  );
}
