
// export const server_url = "http://pr.zarrtechnologies.com/thesecondhandoutlet-backend/api/web";
// export const image_server = "http://pr.zarrtechnologies.com/thesecondhandoutlet-backend/data";

// export const server_url = "https://thesecondhandoutlet.com/backend/api/web";
// export const image_server = "https://thesecondhandoutlet.com/backend/data";

export const server_url = "https://heritagehardwarestore.com/backend/api/web";
export const image_server = "https://heritagehardwarestore.com/backend/data";


export const bannerSize = "1426x362";
export const homePageCategorySize = "292x237";
export const homePagePackageSize = "418x200";
export const productSize = "230x142";

export const slug = (text) => {
  if (text) {
    return text
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }
  return '';
}


export const imageUrl = (url, prefix = 'products', sizePrefix = '120x120-') => `${image_server}/${prefix}/${sizePrefix}${url}`;

// this function is used to show the popup error/success messages.
export const setMessage = (type, message) => {

  let messages = localStorage.getItem("popup_messages");

  let messagesArr = [];

  if (messages) {
    messagesArr = JSON.parse(messages);
  }

  messagesArr.push({
    "type": type,
    "message": message
  });

  localStorage.setItem("popup_messages", JSON.stringify(messagesArr));
}

// this function is used to return the data for popup error/success messages.
export const getMessages = () => {

  let messages = localStorage.getItem("popup_messages");

  let messagesArr = [];

  if (messages) {
    messagesArr = JSON.parse(messages);
  }

  localStorage.removeItem("popup_messages");

  return messagesArr;

}
