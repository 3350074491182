import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./sidebar.css";
import { Collapse } from "react-bootstrap";

export default function Sidebar({
  sidebar,
  setSidebar,
  showSidebar,
  top_menus,
  getMainMenus,
  getChildMenus,
  hasChildMenus,
}) {
  const sideBarRef = useRef();
  let { pathname } = useLocation();

  const [collapseMenu, setCollapseMenu] = useState({ open: false, id: null });

  const activeMenuClass = (menu) => (pathname === menu?.url ? "active-menu" : "");

  const isChildMenusOpened = (menu) =>
    collapseMenu?.open && collapseMenu?.id === menu?.id;

  const onMainMenuClick = (menu) => {
    if (!hasChildMenus(menu)) {
      showSidebar();
    } else {
      setCollapseMenu((prevState) => ({
        id: menu?.id,
        open: prevState.id === menu?.id ? !prevState.open : true,
      }));
    }
  };

  useEffect(() => {
    const handler = (event) => {
      if (!sideBarRef.current.contains(event.target)) {
        setSidebar(false);
        setCollapseMenu({ open: false, id: null });
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div>
      <div
        ref={sideBarRef}
        id='mySidenav'
        className={sidebar ? "sidenav sidenav_active" : "sidenav"}
      >
        <div className='closebtn' onClick={showSidebar}>
          &times;
        </div>

        <ul>
          {getMainMenus(top_menus)?.map((menu) => (
            <li key={menu?.id} onClick={() => onMainMenuClick(menu)}>
              <Link
                to={hasChildMenus(menu) ? "#" : menu?.url}
                className={`hyper ${activeMenuClass(menu)}`}
              >
                <span>{menu?.name}</span>

                {hasChildMenus(menu) && (
                  <span>
                    <i
                      className='fa fa-angle-down'
                      aria-hidden='true'
                      style={{ fontSize: 20, marginLeft: 6 }}
                    />
                  </span>
                )}
              </Link>

              {hasChildMenus(menu) && (
                <Collapse in={isChildMenusOpened(menu)}>
                  <div>
                    <ul className='sidebar-child-menu'>
                      {getChildMenus(top_menus, menu)?.map((item) => (
                        <li key={item?.id} onClick={showSidebar}>
                          <Link to={item?.url} className={`child-menu-item ${activeMenuClass(item)}`}>
                            <span>{item?.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
